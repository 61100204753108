import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import favicon from '../../images/favicon.ico'
import './index.scss'
import './google-fonts.scss'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="Frekvens - Oppdag konserter i Oslo"
      meta={[
        {
          name: 'description',
          content: 'Søk på over 700 konserter og spillesteder i app',
        },
        {
          name: 'keywords',
          content:
            'live music, music, concerts, shows, rock shows, oslo, norway, konserter, musikk, konserter i dag, concerts tonight,concerts this week',
        },
      ]}
    >
      <html lang="nb" />
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
    {children}
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
